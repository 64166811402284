import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useRoutes,
  useNavigate,
} from "react-router-dom";
import HomePage from "./components/HomePage";
import PatientDetail from "../page/patientDetails/PatientDetail";
import Login from "../page/login/Login";
import ProtectedRoute from "../middleware/ProtectedRoute";
import Manish from "./Manish";
import CreateUser from "../page/createuser/CreateUser";
import AdminLeadList from "../admin/leadList/AdminLeadList";
import UserList from "../page/userList/UserList";
import CallLog from "../callLog/CallLog";
import AddPatient from "../page/add-patient/AddPatient";
import AdminHome from "../admin/leadList/AdminHome";
import UpdateProfile from "../page/update-profilee/UpdateProfile";
import BookAppoinment from "../page/netram/BookAppoinment";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute requireLogin={true}>
              <HomePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/manish"
          element={
            <ProtectedRoute requireLogin={true}>
              <Manish />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-user"
          element={
            <ProtectedRoute requireLogin={true}>
              <CreateUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-lead-list"
          element={
            <ProtectedRoute requireLogin={true}>
              <AdminHome />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-patient"
          element={
            <ProtectedRoute requireLogin={true}>
              <AddPatient />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-list"
          element={
            <ProtectedRoute requireLogin={true}>
              <UserList />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/call-log"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <CallLog />
            </ProtectedRoute>
          }
        /> */}
        <Route
          exact
          path="/patient/:did"
          element={
            <ProtectedRoute requireLogin={true}>
              <PatientDetail />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/update-profile"
          element={
            <ProtectedRoute requireLogin={true}>
              <UpdateProfile />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/book-appoinment"
          element={
            <ProtectedRoute requireLogin={true}>
              <BookAppoinment />
            </ProtectedRoute>
          }
        ></Route>
        <Route exact path="/login" element={<Login />}></Route>
      </Routes>
    </>
  );
}

export default App;
