import React, { useState, useEffect } from "react";
import "./bookappoinment.css";
import axios from "axios";

const BookAppoinment = () => {
  const [formData, setFormData] = useState({
    branch: "",
    doctor: "",
    appointmentDate: "",
    appointmentTime: "",
    firstName: "",
    dob: "",
    gender: "",
    address: "",
    contact: "",
    email: "",
    complaints: "",
    scheduleType: "",
  });

  const [branches, setBranches] = useState([]);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axios.get(
          "https://webnetramapi.elihealthsupport.com:2034/Api/GetBranches/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IndlcnR2Y2FmZ2ZmZ2VyZXI0Z2ZnIiwibmJmIjoxNzI2ODkzODM5LCJleHAiOjE3MjY5ODAyMzksImlhdCI6MTcyNjg5MzgzOX0.RKMxeuVvQHNfdaCxHPpLTGUkx_Cr5mHQR3-MprsIzvw"
        );
        setBranches(response.data);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    fetchBranches();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };
  return (
    <>
      <div className="appointment-form-container">
        <h2 className="appointment-form-title">Appointment Form</h2>
        <form className="appointment-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="form-label">Select Branch</label>
            <select
              className="form-select"
              name="branch"
              value={formData.branch}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              {branches.map((branch) => (
                <option key={branch.PK_BranchId} value={branch.BranchCode}>
                  {branch.BranchName} - {branch.BranchAddress}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label className="form-label">Select Doctor</label>
            <select
              className="form-select"
              name="doctor"
              value={formData.doctor}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="doctor1">Doctor 1</option>
              <option value="doctor2">Doctor 2</option>
              <option value="doctor3">Doctor 3</option>
            </select>
          </div>

          <div className="form-group">
            <label className="form-label">Appointment Date (yyyy-mm-dd)</label>
            <input
              className="form-input"
              type="date"
              name="appointmentDate"
              value={formData.appointmentDate}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label">Appointment Time (hh:mm)</label>
            <input
              className="form-input"
              type="time"
              name="appointmentTime"
              value={formData.appointmentTime}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label">First Name</label>
            <input
              className="form-input"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label">Date of Birth (yyyy-mm-dd)</label>
            <input
              className="form-input"
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label">Select Gender</label>
            <select
              className="form-select"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="form-group">
            <label className="form-label">Address</label>
            <input
              className="form-input"
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label">Contact Number</label>
            <input
              className="form-input"
              type="tel"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label">Email</label>
            <input
              className="form-input"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label">Write Complaints</label>
            <textarea
              className="form-textarea"
              name="complaints"
              value={formData.complaints}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label">Select Schedule Type</label>
            <select
              className="form-select"
              name="scheduleType"
              value={formData.scheduleType}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="routine">Routine</option>
              <option value="emergency">Emergency</option>
            </select>
          </div>

          <button className="form-submit-btn" type="submit">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default BookAppoinment;
