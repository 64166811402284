import React, { useEffect, useState } from "react";
import ChatBox from "../components/ChatBox";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import { useParams } from "react-router-dom";
import LeftSlider from "../../Inc/LeftSlider";
import AllTreatmentSelect from "./AllTreatmentSelect";
import UploadReports from "./UploadReports";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";
import "./patientDetail.css";
import PatinetReportList from "./PatinetReportList";
import MessagePopup from "./MessagePopup";
import { decryptId } from "../../utils/cryptoUtils";
import SelectHospital from "./SelectHospital";
import ReportTemplateSend from "./ReportTemplateSend";
import WriteTreatment from "./WriteTreatment";
import FollowUpOne from "./FollowUpOne";
import ConvertedForSelect from "./ConvertedForSelect";
import LeadType from "../../Common/LeadType";
import CloseLead from "./CloseLead";

const PatientDetail = () => {
  const { did } = useParams();
  const id = decryptId(did);
  const {
    leadDetail,
    getLeadbyId,
    patienLeadDetail,
    userLeadDetail,
    patinetReport,
    patientSpeciality,
    patientJourney,
    getConversion,
    patientConversation,
  } = useGetLeadApi();

  const [showMessage, setShowMessage] = useState(false);
  const [writeTreatment, setWriteTreatment] = useState(false);
  const [conversationStatus, setConversationStatus] = useState("");
  const patinetId = patienLeadDetail?.id;
  const userId = userLeadDetail[0]?.id;
  const patientJourneyId = patientJourney?.id;

  const toggleMessage = (message) => {
    console.log("message", message);
    setShowMessage(!showMessage);
    setConversationStatus(message);
  };

  const openTreatment = () => {
    setWriteTreatment(!writeTreatment);
  };

  useEffect(() => {
    getLeadbyId(id);
    getConversion(patinetId, userId, patientJourneyId);
  }, [id, patientJourneyId, patinetId, userId]);

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${day}${getDaySuffix(
      day
    )} ${month} ${year} <span></span> ${formattedHours}:${formattedMinutes} ${period}`;
  };

  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const downloadReports = async (patientReport) => {
    if (!patientReport || patientReport.length === 0) {
      alert("No reports to download");
      return;
    }
    const zip = new JSZip();
    const folder = zip.folder("patientReports");

    const promises = patientReport.map(async (report) => {
      const response = await axios.get(report.reports, {
        responseType: "blob",
      });
      const filename = report.reports.split("/").pop();
      folder.file(filename, response.data);
    });

    await Promise.all(promises);
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "patientReports.zip");
  };

  let patientName = leadDetail?.patient_name;
  let patientPhone = leadDetail?.patient_phone;

  if (leadDetail?.platform === "Tawk_TO" && patientName) {
    const nameParts = patientName.split("\r\n");
    const nameOnly = nameParts[0]; // The first line is the name
    const phoneLine = nameParts.find((part) => part.startsWith("Phone"));

    patientName = nameOnly;

    if (phoneLine) {
      const phoneMatch = phoneLine.match(/Phone\s*:\s*(\d+)/);
      if (phoneMatch) {
        patientPhone = phoneMatch[1];
      }
    }
  }

  const getBackgroundColor = (lead_type) => {
    switch (lead_type) {
      case "Warm":
        return "#c6c61c";
      case "Hot":
        return "green";
      case "Cold":
        return "orange";
      case "Dead":
        return "red";
      default:
        return "gray";
    }
  };

  const UpdatesImg = () => {
    return (
      <div
        className="updates-img"
        style={{
          background: getBackgroundColor(leadDetail?.lead_type),
        }}
      ></div>
    );
  };

  const lastWelcomeConversation = patientConversation
    ?.filter((e) => e.status === "Welcome-Conversation")
    ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))?.[0];

  const lastfollowUpOne = patientConversation
    ?.filter((e) => e.status === "Follow-Up-One")
    ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))?.[0];

  const lastfollowUpTwo = patientConversation
    ?.filter((e) => e.status === "Follow-Up-Two")
    ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))?.[0];

  const lastfollowUpThree = patientConversation
    ?.filter((e) => e.status === "Follow-Up-Three")
    ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))?.[0];

  const lastfollowUpFour = patientConversation
    ?.filter((e) => e.status === "Follow-Up-Four")
    ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))?.[0];

  return (
    <>
      <section id="medflick-mod">
        <div className="head-top">
          <div className="head-top-left">
            {" "}
            <img src="/images/wavikar-logo.png" />{" "}
          </div>
          <div className="head-top-right">
            <a href="#">
              {" "}
              <i className="fa  fa-angle-left"></i> Go back{" "}
            </a>
          </div>
        </div>

        <div className="medflick-mod">
          <LeftSlider />

          <div className="medflick-mod-right">
            <div className="scrollbar-1">
              <div className="patient-dashboard-doc">
                <div className="patient-dashboard-box">
                  <div className="patient-img-box">
                    <div
                      className="css-hra0ve"
                      style={{
                        background: `repeating-conic-gradient(${getBackgroundColor(
                          leadDetail?.lead_type
                        )} 0deg, ${getBackgroundColor(
                          leadDetail?.lead_type
                        )} 18deg, transparent 18deg, transparent 30deg)`,
                      }}
                    ></div>
                    <img src="/images/2024/01/p.jpg" />
                  </div>
                  <div className="patient-doc-box">
                    <h2>{patientName}</h2>
                    <div className="patient-doc-data">
                      <ul>
                        {/* <li>
                          <img src="/images/2024/01/03/1.png" /> Female
                        </li>
                        <li>
                          <img src="/images/2024/01/03/2.png" /> India
                        </li> */}
                        <li>
                          <img src="/images/2024/01/03/3.png" />{" "}
                          {patientSpeciality?.name}
                        </li>
                        <li>
                          <img src="/images/2024/01/03/4.png" /> {patientPhone}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="patient-dashboard-query">
                  <h3>Query</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formatDate(leadDetail?.created_at),
                    }}
                  />
                </div>
                <div className="patient-dashboard-reply">
                  <h3>Source</h3>
                  <p>{leadDetail ? leadDetail.platform : "Manually Add"}</p>
                </div>
                <div className="patient-dashboard-msg">
                  <LeadType
                    leadId={id}
                    leadtype={leadDetail?.lead_type}
                    getLeadbyId={getLeadbyId}
                  />
                  {/* <a
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Message
                  </a> */}
                </div>
              </div>

              <div className="patient-dashboard-casebox">
                <div className="patient-dashboard-case-left">
                  <h2>
                    Case History{" "}
                    {/* <a href="#">
                      <img src="/images/2024/01/download.png" /> Download
                    </a> */}
                  </h2>

                  <div className="stage-text">
                    Stage <span>1/4</span>
                  </div>
                  <ul>
                    <li className={` ${patientSpeciality?.id ? "active" : ""}`}>
                      <UpdatesImg />
                      <p>Medical Condition</p>
                      {patientSpeciality?.name && (
                        <>
                          <p>{patientSpeciality?.name}</p>
                          <div
                            className="up-datalist"
                            dangerouslySetInnerHTML={{
                              __html: formatDate(patientSpeciality?.created_at),
                            }}
                          />
                        </>
                      )}
                    </li>
                    <li
                      className={` ${patinetReport.length > 0 ? "active" : ""}`}
                    >
                      <UpdatesImg />

                      <p>Report Upload</p>
                      {patinetReport?.length > 0 && (
                        <div
                          className="up-datalist"
                          dangerouslySetInnerHTML={{
                            __html: formatDate(patinetReport[0]?.created_at),
                          }}
                        />
                      )}
                    </li>
                    <li
                      className={` ${lastWelcomeConversation ? "active" : ""}`}
                    >
                      <UpdatesImg />
                      <p>Welcome Conversation</p>
                      <p>
                        {lastWelcomeConversation?.health_description || null}
                      </p>
                      {lastWelcomeConversation?.created_at && (
                        <p
                          style={{ fontSize: "12px", color: "#ff6800" }}
                          dangerouslySetInnerHTML={{
                            __html: formatDate(
                              lastWelcomeConversation?.created_at
                            ),
                          }}
                        />
                      )}
                    </li>
                    <li className={` ${lastfollowUpOne ? "active" : ""}`}>
                      <UpdatesImg />
                      <p>Follow Up (1)</p>
                      <p>{lastfollowUpOne?.health_description || null}</p>
                      {lastfollowUpOne?.created_at && (
                        <p
                          style={{ fontSize: "12px", color: "#ff6800" }}
                          dangerouslySetInnerHTML={{
                            __html: formatDate(lastfollowUpOne?.created_at),
                          }}
                        />
                      )}
                    </li>
                    <li className={` ${leadDetail?.stages ? "active" : ""}`}>
                      <UpdatesImg />
                      <p>Converted For</p>

                      <p>{leadDetail?.stages}</p>
                    </li>
                    <li className={` ${lastfollowUpTwo ? "active" : ""}`}>
                      <UpdatesImg />
                      <p>Follow Up (2)</p>
                      <p>{lastfollowUpTwo?.health_description || null}</p>
                      {lastfollowUpTwo?.created_at && (
                        <p
                          style={{ fontSize: "12px", color: "#ff6800" }}
                          dangerouslySetInnerHTML={{
                            __html: formatDate(lastfollowUpTwo?.created_at),
                          }}
                        />
                      )}
                    </li>
                    <li className={` ${lastfollowUpThree ? "active" : ""}`}>
                      <UpdatesImg />
                      <p>Follow Up (3)</p>
                      <p>{lastfollowUpThree?.health_description || null}</p>
                      {lastfollowUpThree?.created_at && (
                        <p
                          style={{ fontSize: "12px", color: "#ff6800" }}
                          dangerouslySetInnerHTML={{
                            __html: formatDate(lastfollowUpThree?.created_at),
                          }}
                        />
                      )}
                    </li>
                    <li className={` ${lastfollowUpFour ? "active" : ""}`}>
                      <UpdatesImg />
                      <p>Follow Up (4)</p>
                      <p>{lastfollowUpFour?.health_description || null}</p>
                      {lastfollowUpFour?.created_at && (
                        <p
                          style={{ fontSize: "12px", color: "#ff6800" }}
                          dangerouslySetInnerHTML={{
                            __html: formatDate(lastfollowUpFour?.created_at),
                          }}
                        />
                      )}
                    </li>
                    <li>
                      <UpdatesImg />
                      <p>Conversation Status</p>
                      <p>
                        {leadDetail?.status === "Close Lead"
                          ? "Closed"
                          : "Open"}
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="patient-dashboard-case-right">
                  <div className="patient-dashboard-reports">
                    <h2>
                      Reports{" "}
                      <AllTreatmentSelect
                        patientId={patienLeadDetail?.id}
                        userId={userLeadDetail[0]?.id}
                        leadId={id}
                        treatmentId={patientSpeciality?.id}
                        getLeadbyId={getLeadbyId}
                        id={id}
                      />
                      <a
                        href="#"
                        onClick={openTreatment}
                        style={{
                          background: getBackgroundColor(leadDetail?.lead_type),
                        }}
                      >
                        Write Procedure
                      </a>
                      {/* <ReportTemplateSend
                        leadPhoneNumber={patienLeadDetail?.phone}
                        leadName={patienLeadDetail?.name}
                      /> */}
                      <a
                        onClick={() => downloadReports(patinetReport)}
                        style={{
                          background: getBackgroundColor(leadDetail?.lead_type),
                          color: "#fff",
                        }}
                      >
                        <img src="/images/2024/01/download.png" /> Download
                      </a>
                    </h2>
                    <div className="dashboard-upload-reports">
                      <PatinetReportList
                        patinetReport={patinetReport}
                        formatDate={formatDate}
                        getLeadbyId={getLeadbyId}
                        leadId={id}
                      />
                      <UploadReports
                        patinetId={patienLeadDetail?.id}
                        userId={userLeadDetail[0]?.id}
                        getLeadbyId={getLeadbyId}
                        id={id}
                        patientSpeciality={patientSpeciality}
                      />
                    </div>
                  </div>
                  {/* <SelectHospital /> */}

                  <div className="patient-dashboard-opinion">
                    <h2>
                      Book Appointment to Netram{" "}
                      <a
                        href="#"
                        onClick={() => toggleMessage("Welcome-Conversation")}
                        style={{
                          background: getBackgroundColor(leadDetail?.lead_type),
                        }}
                      >
                        Book Now
                      </a>
                    </h2>
                  </div>

                  <div className="patient-dashboard-opinion">
                    <h2>
                      Welcome Conversation{" "}
                      <ReportTemplateSend
                        leadPhoneNumber={patienLeadDetail?.phone}
                        getBackgroundColor={getBackgroundColor}
                        leadType={leadDetail?.lead_type}
                      />
                      <a
                        href="#"
                        onClick={() => toggleMessage("Welcome-Conversation")}
                        style={{
                          background: getBackgroundColor(leadDetail?.lead_type),
                        }}
                      >
                        Update Conversation
                      </a>
                    </h2>
                    {patientConversation
                      ?.filter((e) => e.status === "Welcome-Conversation") // Filter by status
                      .map((e) => (
                        <div key={e?.id}>
                          <p>{e?.health_description}</p>
                          <p
                            style={{ fontSize: "12px", color: "#ff6800" }}
                            dangerouslySetInnerHTML={{
                              __html: formatDate(e?.created_at),
                            }}
                          />
                        </div>
                      ))}

                    {/* <div className="pdf-file">Lorem ipsum.pdf</div> */}
                  </div>

                  <div className="patient-dashboard-opinion">
                    <h2>
                      Follow Up (1)
                      <AllTreatmentSelect
                        patientId={patienLeadDetail?.id}
                        userId={userLeadDetail[0]?.id}
                        leadId={id}
                        treatmentId={patientSpeciality?.id}
                        getLeadbyId={getLeadbyId}
                        id={id}
                      />
                      <a
                        href="#"
                        style={{
                          background: getBackgroundColor(leadDetail?.lead_type),
                        }}
                      >
                        Send Product Template
                      </a>
                      <a
                        href="#"
                        onClick={() => toggleMessage("Follow-Up-One")}
                        style={{
                          background: getBackgroundColor(leadDetail?.lead_type),
                        }}
                      >
                        Update Conversation
                      </a>
                    </h2>
                    {patientConversation
                      ?.filter((e) => e.status === "Follow-Up-One")
                      .map((e) => (
                        <div key={e?.id}>
                          <p>{e?.health_description}</p>
                          <p
                            style={{ fontSize: "12px", color: "#ff6800" }}
                            dangerouslySetInnerHTML={{
                              __html: formatDate(e?.created_at),
                            }}
                          />
                        </div>
                      ))}
                    {/* <div className="pdf-file">Lorem ipsum.pdf</div> */}
                  </div>

                  <div className="patient-dashboard-opinion">
                    <h2>
                      <ConvertedForSelect
                        leadId={id}
                        stages={leadDetail?.stages}
                        getLeadbyId={getLeadbyId}
                      />
                      Converted For
                      {/* <a href="#" onClick={toggleMessage}>
                        Send Follow Up Template
                      </a>
                      <a href="#" onClick={toggleMessage}>
                        Update Message
                      </a> */}
                    </h2>
                  </div>

                  <div className="patient-dashboard-opinion">
                    <h2>
                      Follow Up (2)
                      {/* <FollowUpOne /> */}
                      <a
                        href="#"
                        // onClick={() => toggleMessage("Follow-Up-Two")}
                        style={{
                          background: getBackgroundColor(leadDetail?.lead_type),
                        }}
                      >
                        Send Video Template
                      </a>
                      <a
                        href="#"
                        onClick={() => toggleMessage("Follow-Up-Two")}
                        style={{
                          background: getBackgroundColor(leadDetail?.lead_type),
                        }}
                      >
                        Update Conversation
                      </a>
                    </h2>
                    {patientConversation
                      ?.filter((e) => e.status === "Follow-Up-Two")
                      .map((e) => (
                        <div key={e?.id}>
                          <p>{e?.health_description}</p>
                          <p
                            style={{ fontSize: "12px", color: "#ff6800" }}
                            dangerouslySetInnerHTML={{
                              __html: formatDate(e?.created_at),
                            }}
                          />
                        </div>
                      ))}
                    {/* <div className="pdf-file">Lorem ipsum.pdf</div> */}
                  </div>

                  <div className="patient-dashboard-opinion">
                    <h2>
                      Follow Up (3)
                      <a
                        href="#"
                        style={{
                          background: getBackgroundColor(leadDetail?.lead_type),
                        }}
                      >
                        Send Follow Up Template
                      </a>
                      <a
                        href="#"
                        onClick={() => toggleMessage("Follow-Up-Three")}
                        style={{
                          background: getBackgroundColor(leadDetail?.lead_type),
                        }}
                      >
                        Update Conversation
                      </a>
                    </h2>
                    {patientConversation
                      ?.filter((e) => e.status === "Follow-Up-Three")
                      .map((e) => (
                        <div key={e?.id}>
                          <p>{e?.health_description}</p>
                          <p
                            style={{ fontSize: "12px", color: "#ff6800" }}
                            dangerouslySetInnerHTML={{
                              __html: formatDate(e?.created_at),
                            }}
                          />
                        </div>
                      ))}
                    {/* <div className="pdf-file">Lorem ipsum.pdf</div> */}
                  </div>

                  <div className="patient-dashboard-opinion">
                    <h2>
                      Follow Up (4)
                      <a
                        href="#"
                        style={{
                          background: getBackgroundColor(leadDetail?.lead_type),
                        }}
                      >
                        Send Follow Up Template
                      </a>
                      <a
                        href="#"
                        onClick={() => toggleMessage("Follow-Up-Four")}
                        style={{
                          background: getBackgroundColor(leadDetail?.lead_type),
                        }}
                      >
                        Update Conversation
                      </a>
                    </h2>

                    {patientConversation
                      ?.filter((e) => e.status === "Follow-Up-Four")
                      .map((e) => (
                        <div key={e?.id}>
                          <p>{e?.health_description}</p>
                          <p
                            style={{ fontSize: "12px", color: "#ff6800" }}
                            dangerouslySetInnerHTML={{
                              __html: formatDate(e?.created_at),
                            }}
                          />
                        </div>
                      ))}
                    {/* <div className="pdf-file">Lorem ipsum.pdf</div> */}
                  </div>

                  <div className="patient-dashboard-opinion">
                    <h2>
                      <CloseLead
                        leadId={id}
                        status={leadDetail?.status}
                        getLeadbyId={getLeadbyId}
                        getBackgroundColor={getBackgroundColor}
                        leadType={leadDetail?.lead_type}
                      />
                      <a
                        href="#"
                        onClick={toggleMessage}
                        style={{
                          background: getBackgroundColor(leadDetail?.lead_type),
                        }}
                      >
                        Your Feedback
                      </a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MessagePopup
        toggleMessage={toggleMessage}
        showMessage={showMessage}
        patientId={patienLeadDetail?.id}
        userId={userLeadDetail[0]?.id}
        getConversion={getConversion}
        patientJourneyId={patientJourneyId}
        conversationStatus={conversationStatus}
      />
      <WriteTreatment
        toggleMessage={openTreatment}
        showMessage={writeTreatment}
        patientId={patienLeadDetail?.id}
        userId={userLeadDetail[0]?.id}
        leadId={id}
        treatmentId={patientSpeciality?.id}
        getLeadbyId={getLeadbyId}
        id={id}
      />
      <ChatBox />
    </>
  );
};

export default PatientDetail;
